import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  apiHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';

export const paginatedListCoupons = (params, reset) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.PAGINATED_LIST_COUPONS) });
  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}admin/coupons?${params}`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.PAGINATED_LIST_COUPONS),
      payload: { ...data, reset }
    });
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.PAGINATED_LIST_COUPONS)
    });
  }
};

export const getCoupon = (couponId, reset) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_COUPON_DETAIL) });
  const requestOptions = {
    method: 'GET',
    headers: apiHeader()
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}admin/coupons/${couponId}`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.GET_COUPON_DETAIL),
      payload: { ...data, reset }
    });
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.GET_COUPON_DETAIL)
    });
  }
};

export const createAndUpdateCoupon = (payload) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.CREATE_AND_UPDATE_COUPON) });
  const requestOptions = {
    method: 'POST',
    headers: apiHeader(),
    body: JSON.stringify(payload)
  };

  try {
    const response = await fetch(
      `${getEnvironment().API_URL}admin/coupons`,
      requestOptions
    );
    const data = await processResponse(response);
    dispatch({
      type: successReqActionType(actionConstants.CREATE_AND_UPDATE_COUPON),
      payload: data
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.CREATE_AND_UPDATE_COUPON) });
  }
};

export const deleteCoupon = couponId => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.DELETE_COUPON) });
  const requestOptions = {
    method: 'DELETE',
    headers: apiHeader(),
  };

  try {
    const data = await fetch(
      `${getEnvironment().API_URL}admin/coupons/${couponId}`,
      requestOptions
    );
    await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.DELETE_COUPON),
      payload: { couponId }
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.DELETE_COUPON) });
  }
};

export const getRedeemedUsers = async couponId => {
  const requestOptions = {
    method: 'GET',
    headers: apiHeader(),
  };

  try {
    const data = await fetch(
      `${getEnvironment().API_URL}admin/coupons/${couponId}/users`,
      requestOptions
    );
    const retData = await processResponse(data);
    return retData;
  } catch (error) {
    return [];
  }
};