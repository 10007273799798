import { UserName } from 'components/UserName';
import { convertUTCtoLocal } from 'helper/utils';
import {
  Button,
  Modal,
  Table
} from 'react-bootstrap';

const RedeemedUsersDialog = ({ onClose, redeemedUsers }) => {
  return (
    <Modal show={true} onHide={onClose} backdrop='static' size='xl' className='user-credit-history-dialog'>
      <Modal.Header>Redeemed Users</Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>User Name</th>
              <th>Redeemed Date</th>
            </tr>
          </thead>
          <tbody>
            {
              redeemedUsers && redeemedUsers.length > 0 && redeemedUsers.map((item) => (
                <tr>
                  <td><UserName primary_user_id={item.user_id} /></td>
                  <td>{convertUTCtoLocal(item.redeemed_at).format('yyyy-MM-DD HH:mm')}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { RedeemedUsersDialog };
