import './CouponListItem.scss';
import { convertUTCtoLocal } from 'helper/utils';
import { UserName } from 'components/UserName';
import { Link, useNavigate } from 'react-router-dom';

const CouponListItem = props => {
  const { coupon } = props;
  const navigate = useNavigate();

  if (!coupon) {
    return <></>;
  }

  const {
    code,
    expiry_date,
    incentive_credits,
    redeemed_count,
    for_new_users_only,
    active,
    created_at,
    manager_id
  } = coupon;

  return (
    <tr key={code} className='coupon-list-item'>
      <td className='id'>
        <Link to={`/coupon/${code}`} className='text-info'>{code}</Link>
      </td>
      <td className='app-name'>{convertUTCtoLocal(expiry_date).format('yyyy-MM-DD')}</td>
      <td className='option'>{incentive_credits || ''}</td>
      <td className='quantity'>{for_new_users_only == 1 ? 'Yes' : 'No'}</td>
      <td className='option'>{redeemed_count || '0'}</td>
      <td className='option'>{active == 1 ? 'Yes' : 'No'}</td>
      <td className='username'>
        <UserName primary_user_id={manager_id} />
      </td>
      <td className='created-at'>
        <span>{convertUTCtoLocal(created_at).format('yyyy-MM-DD HH:mm')}</span>
      </td>
    </tr>
  );
};

export { CouponListItem };
