import moment from 'moment';
export const CustomLegend = props => {
  const { payload, labels } = props;
  return (
    <div className='custom-legend'>
      {payload.map(item => (
        <div className='legend-item' style={{ color: item.color }}>
          <div className='color-box' style={{ background: item.color }} />
          {labels[item.payload.dataKey]}
        </div>
      ))}
    </div>
  );
};

export const CustomTooltip = ({ active, payload, labels, showDay = false }) => {
  if (active && payload && payload.length) {
    let dateString = '';
    if (payload[0] && payload[0].payload && payload[0].payload.day) {
      dateString = moment(payload[0].payload.day).format('MM/DD/YYYY');
    }
    return (
      <div className='custom-tooltip'>
        {payload.map(item => (
          <p className='label'>
            {`${labels[item['dataKey']] || item['dataKey']}`}:{' '}
            <strong>{item.value}</strong>
          </p>
        ))}
        {showDay && dateString && <p className='label'>Date: {dateString}</p>}
      </div>
    );
  }

  return null;
};
