import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import QRCode from 'qrcode';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import moment from 'moment'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.module.css';
import {
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  Alert,
  Modal,
  Dropdown,
  Badge
} from 'react-bootstrap';
import {
  getCoupon,
  createAndUpdateCoupon,
  getRedeemedUsers,
  deleteCoupon
} from 'redux-layer/actions';

import './CouponDetail.scss';
import { convertUTCtoLocal, copyToClipboard, getAttributeFromUser, getUserAttribute } from 'helper/utils';
import { NotificationManager } from 'react-notifications';
import { DISCOUNT_OPTIONS, PRICE_PLANS, VIMMERSE_CUSTOMER_ID } from 'helper/constants';
import { RedeemedUsersDialog } from './RedeemedUsers';
import { QRCodeDialog } from './QRCodeDialog';

const CouponDetail = props => {
  const { coupon_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [credits, setCredits] = useState(0);
  const [forNewUser, setForNewUser] = useState(false);  
  const [active, setActive] = useState(true);

  const [redeemedUsers, setRedeemedUsers] = useState([]);
  const [showRedeemedUsers, setShowRedeemedUsers] = useState(false);
  const [showQRcode, setShowQRCode] = useState(false);

  const { paginatedCoupons } = useSelector(state => ({
    paginatedCoupons: state.coupons.paginatedCoupons
  }));

  useEffect(() => {
    if (coupon_id !== 'new') {
      dispatch(getCoupon(coupon_id));
    }
  }, []);

  useEffect(() => {
    console.log(expiryDate);
  }, [expiryDate])

  useEffect(() => {
    if (coupon_id && coupon_id !== 'new') {
      const coupon = paginatedCoupons.find(coupon => coupon.code === coupon_id);
      if (coupon) {
        setCode(coupon['code']);
        setExpiryDate(coupon['expiry_date']);
        setCredits(coupon['incentive_credits']);
        setForNewUser(coupon['for_new_users_only']);
        setActive(coupon['active'])
      } else {
        dispatch(getCoupon(coupon_id));
      }
    }
  }, [coupon_id, paginatedCoupons, dispatch]);

  const currentCoupon = paginatedCoupons.find(coupon => coupon.code === coupon_id);

  const handleSubmit = () => {
    if (code === '') {
      NotificationManager.error('Code is required', 'Submission Error');
      return;
    }

    if (!checkValidCode(code)) {
      NotificationManager.error("Code must contain 'a-z' and 'A-Z' and '_' and '-'", 'Submission Error');
      return;
    }
    if (!expiryDate) {
      NotificationManager.error('Expiry Date is required', 'Submission Error');
      return;
    }
    if (credits == 0) {
      NotificationManager.error('Credits is required', 'Submission Error');
      return;
    }

    let payload = {
      code,
      expiry_date: expiryDate,
      incentive_credits: credits,
      for_new_users_only: forNewUser,
      active
    };

    dispatch(createAndUpdateCoupon(payload));
    NotificationManager.success("Created new coupon", 'Submission Success');
  };

  const onViewRedeemedUsers = async () => {
    try {
      const response = await getRedeemedUsers(coupon_id);
      setRedeemedUsers(response);
      setShowRedeemedUsers(true);
    } catch (error) {
      console.log(error);
    }
  }

  const onDeleteCoupon = () => {
    dispatch(deleteCoupon(coupon_id));
    navigate('/coupons');
  };

  const checkValidCode = (str) => {
    const regex = /^[a-zA-Z0-9_-]+$/;
    return regex.test(str);
  }

  const envString = localStorage.getItem('myEnv');
  let envName = 'prod';
  if (envString) {
    const envValue = JSON.parse(envString);
    envName = envValue.value;
  }
  const couponUrl = (envName === 'prod' ? 'https://www.vimmerse.net' : 'https://www.vimmerse.co') + '?coupon_code=' + coupon_id;

  return (
    <div className='coupon-detail-page'>
      <div className='page-header'>
        <h1>
          {coupon_id === 'new' ? 'New Coupon' : coupon_id}
          {!(currentCoupon && currentCoupon.active == 1) ? (
            <Badge className='bg-secondary ms-2'>Deactive</Badge>
          ) : (
            ''
          )}
        </h1>
        {
          currentCoupon && (
            <div className='actions'>
              <Dropdown
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Dropdown.Toggle variant='primary' id='dropdown-play' size='md'>
                  Actions
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey='delete' onClick={() => copyToClipboard(couponUrl)}>
                    Copy Coupon Link
                  </Dropdown.Item>
                  <Dropdown.Item eventKey='delete' onClick={() => setShowQRCode(true)}>
                    Generate QR Code
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey='delete' onClick={onDeleteCoupon}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )
        }
      </div>
      <div className='container'>
        <h3>Required Fields</h3>
        <div className='row'>
          <div className='col-md-4'>
            <FormGroup>
              <FormLabel required>Code *</FormLabel>
              <FormControl
                type='text'
                value={code}
                disabled={coupon_id && coupon_id !== 'new'}
                required
                placeholder='Code'
                onChange={e => setCode(e.target.value)}
              />
            </FormGroup>
          </div>          
          <div className='col-md-4'>
            <FormGroup>
              <FormLabel required>Incentive Credits *</FormLabel>
              <FormControl
                type='text'
                value={credits}
                required
                placeholder='Credits'
                onChange={e => setCredits(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-4'>
            <FormGroup>
              <FormLabel>Expiry Date *</FormLabel>
              <DatePicker 
                className='form-control'
                selected={expiryDate}
                onChange={(date) => setExpiryDate(moment(date).format('YYYY-MM-DD'))}
                dateFormat="yyyy-MM-dd"
              />
            </FormGroup>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-4'>
            <FormGroup>
              <div className='mb-2'>For New Users Only *</div>
              <Switch
                onChange={(value) => setForNewUser(value)}
                checked={forNewUser}
              />
            </FormGroup>
          </div>
          <div className='col-md-4'>
            <FormGroup>
              <div className='mb-2'>Active *</div>
              <Switch
                onChange={(value) => setActive(value)}
                checked={active}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <Button
              onClick={handleSubmit}
              variant='primary'
              disabled={code === ''}
            >
              Save
            </Button>
          </div>
        </div>

        {currentCoupon && (
          <>
            <hr />
            <div className='row'>
              <div className='col-md-3'>
                <FormGroup>
                  <FormLabel>Redeemed Count</FormLabel>
                  <p>
                    {currentCoupon.redeemed_count}
                  </p>
                </FormGroup>
              </div>
              <div className='col-md-3'>
                <FormGroup>
                  <FormLabel>Created By</FormLabel>
                  <p>
                    {currentCoupon.manager_id}
                  </p>
                </FormGroup>
              </div>
              <div className='col-md-3'>
                <FormGroup>
                  <FormLabel>Created At</FormLabel>
                  <p>
                    {convertUTCtoLocal(currentCoupon.created_at).format('yyyy-MM-DD HH:mm')}
                  </p>
                </FormGroup>
              </div>
              <div className='col-md-3'>
                <FormGroup>
                  <FormLabel>Updated At</FormLabel>
                  <p>
                    {convertUTCtoLocal(currentCoupon.updated_at).format('yyyy-MM-DD HH:mm')}
                  </p>
                </FormGroup>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <Button
                  variant='primary'
                  onClick={() => onViewRedeemedUsers()}
                  className=''
                >
                  See Redeemed Users
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
      {showRedeemedUsers && (
        <RedeemedUsersDialog
          onClose={() => setShowRedeemedUsers(false)}
          redeemedUsers={redeemedUsers}
        />
      )}
      {
        showQRcode && (
          <QRCodeDialog
            onClose={() => setShowQRCode(false)}
            couponUrl={couponUrl}
          />
        )
      }
    </div>
  );
};

export { CouponDetail };
