/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Table,
  Alert
} from 'react-bootstrap';
import './CouponsList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { paginatedListCoupons } from 'redux-layer/actions';
import { VimmersePaginationBar } from 'components/VimmersePaginationBar';
import { CustomerSelect } from 'components/CustomerSelect';
import { CouponListItem } from 'components/CouponListItem/CouponListItem';

const CouponsList = props => {
  const queryParam = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    paginatedCoupons,
    lastEvaluatedKeys,
    totalCount,
    isLoadingCouponsList
  } = useSelector(state => ({
    paginatedCoupons: state.coupons.paginatedCoupons,
    lastEvaluatedKeys: state.coupons.lastEvaluatedKeys,
    totalCount: state.coupons.totalCount,
    isLoadingCouponsList: state.coupons.isLoadingCouponsList
  }));

  // const [ratingFilter, setRatingFilter] = useState([]);
  const [createdByFilter, setCreatedByFilter] = useState(
    queryParam.get('username') || ''
  );

  const [perPage, setPerPage] = useState(40);
  const [page, setPage] = useState(1);

  const searchParams = useSearchParams();

  useEffect(() => {
    setCreatedByFilter(searchParams[0].get('created_by', ''));

    // const ratingValue = searchParams[0].get('rating') || '';
    // const rataingValues = ratingValue.split(',');
    // const selectedRatings = RATING_OPTIONS.filter(ro =>
    //   rataingValues.includes(ro.value)
    // );
    // setRatingFilter(selectedRatings);

    const queryString = searchParams[0].toString();
    onStartSearch(queryString, true);
  }, []);

  const onStartSearch = (queryString, reset = false) => {
    dispatch(paginatedListCoupons(queryString, reset));
  };

  const onSearch = (reset = false) => {
    if (reset) {
      setPage(1);
    }

    const params = new URLSearchParams({
      created_by: createdByFilter || '',
      // rating: ratingFilter.map(obj => obj.value).join(','),
      last_evaluated_key: reset ? '' : JSON.stringify(lastEvaluatedKeys)
    });
    const queryString = params.toString();
    onStartSearch(queryString, reset);
    navigate(`/coupons?${queryString}`);
  };

  const onChangeRowsPerPage = newPerPage => {
    setPerPage(newPerPage);
    setPage(1);
  };

  const onChangePage = page => {
    const required_count = page * perPage;
    if (required_count > paginatedCoupons.length) {
      if (lastEvaluatedKeys) {
        onSearch();
      } else {
        return;
      }
    }

    setPage(page);
  };

  let totalPages = 0;

  if (!lastEvaluatedKeys) {
    if (paginatedCoupons.length) {
      totalPages = Math.ceil(totalCount / perPage);
    }
  }

  const displayingCoupons = paginatedCoupons.slice(
    (page - 1) * perPage,
    page * perPage
  );

  return (
    <div className='sessions-list-page'>
      <div className='page-header'>
        <h1>Coupons List</h1>
        <div>
          <Link to={'/coupon/new'} className='btn btn-primary'>
            New Coupon
          </Link>
        </div>
      </div>
      <div className='contents'>
        {/* <div className='row align-items-end pb-2'>
          <div className='col-md-2'>
            <Button onClick={() => onSearch(true)}>Search</Button>
          </div>
        </div> */}
        <div className='mt-2'>
          <VimmersePaginationBar
            onChangePage={onChangePage}
            currentPage={page}
            isLastPage={!lastEvaluatedKeys && totalPages === page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPage={perPage}
          />
          {isLoadingCouponsList && (
            <Alert variant='info'>Loading Coupons...</Alert>
          )}
          <Table striped responsive>
            <thead>
              <td>Code</td>
              <td>Expire Date</td>
              <td>Incentive Credits</td>
              <td>For New Users Only</td>
              <td>Redeemed Count</td>
              <td>Active</td>
              <td>Created By</td>
              <td>Created At</td>
            </thead>
            <tbody>
              {displayingCoupons.map(pm => (
                <CouponListItem coupon={pm} key={pm.id} />
              ))}
            </tbody>
          </Table>
          <VimmersePaginationBar
            onChangePage={onChangePage}
            currentPage={page}
            isLastPage={!lastEvaluatedKeys && totalPages === page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPage={perPage}
          />
        </div>
      </div>
    </div>
  );
};

export { CouponsList };
