import { actionConstants } from '../constants';
import {
  failReqActionType,
  startReqActionType,
  successReqActionType
} from 'helper/requestHelper';

const initialState = {
  isLoadingStatistics: false,
  isLoadingRecentAiUsage: false,

  recentAIUsages: [],
  statistics: [],

  error: null
};

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.GET_USER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: true
      };
    case successReqActionType(actionConstants.GET_USER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: false,
        userStatistics: action.payload
      };

    case startReqActionType(actionConstants.GET_CUSTOMER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: true
      };
    case successReqActionType(actionConstants.GET_CUSTOMER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: false,
        customerStatistics: action.payload
      };

    case startReqActionType(actionConstants.GET_AI_RECENT_USAGE):
      return {
        ...state,
        isLoadingRecentAiUsage: true
      };
    case successReqActionType(actionConstants.GET_AI_RECENT_USAGE):
      return {
        ...state,
        recentAIUsages: action.payload,
        isLoadingRecentAiUsage: false
      };
    case failReqActionType(actionConstants.GET_AI_RECENT_USAGE):
      return {
        ...state,
        recentAIUsages: [],
        isLoadingRecentAiUsage: false
      };
    case startReqActionType(actionConstants.GET_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: true
      };
    case successReqActionType(actionConstants.GET_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: false,
        statistics: action.payload
      };
    case failReqActionType(actionConstants.GET_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: false,
        statistics: null
      };
    default:
      return state;
  }
};

export default statisticsReducer;
