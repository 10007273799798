/* eslint-disable react-hooks/exhaustive-deps */
import { IMPORTANT_CUSTOMERS } from 'helper/constants';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10
  }
});

const colorStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      ...dot(data.color)
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
};

const CustomerSelect = props => {
  const { allCustomers } = useSelector(state => ({
    allCustomers: state.customers.allCustomers
  }));

  useEffect(() => {
    const customerString = localStorage.getItem('customer');
    if (customerString) {
      const customerValue = JSON.parse(customerString);
      if (props.onChangeCustomer) {
        props.onChangeCustomer(customerValue);
      }
    }
  }, []);

  const customersOptions = Object.values(allCustomers).map(co => {
    const customerOption = {
      value: co.id,
      label: co.name,
      customer_id: co.customer_id,
      customer_key: co.key || ''
    }
    if (IMPORTANT_CUSTOMERS.includes(co.customer_id)) {
      customerOption['color'] = 'green';
    }
    return customerOption;
  });

  const sortedCustomerOptions = customersOptions.sort((a, b) => {
    if (a.color && !b.color) {
      return -1
    }
    return 1;
  })

  const onChangeCustomer = value => {
    localStorage.setItem('customer', JSON.stringify(value));
    if (props.onChangeCustomer) {
      props.onChangeCustomer(value);
    }
  };

  return (
    <Select
      isMulti={props.isMulti || false}
      className='env-selector mb-1'
      classNamePrefix='env-select'
      onChange={onChangeCustomer}
      options={sortedCustomerOptions}
      value={props.customer}
      styles={colorStyles}
    />
  );
};

export { CustomerSelect };
