export const CAPTURE_TYPE_AUTO = 'Auto';
export const CAPTURE_TYPE_IPHONE_VIMMERSE = 'iPhone-Vimmerse';
export const CAPTURE_TYPE_IPHONE_RECORD3D = 'iPhone-Record3D';
export const CAPTURE_TYPE_MULTI_CAMERA_MIV = 'MultiCamera-MIV';
export const CAPTURE_TYPE_MULTI_KINECT_DEPTH_KIT = 'MultiKinect-Depthkit';
export const CAPTURE_TYPE_MULTI_KINECT_OPEN3D = 'MultiKinect-Open3D';
export const CAPTURE_TYPE_3DVIDEO_VIMMERSE = '3dVideo-Vimmerse';

export const CAPTURE_OPTIONS = [
  {
    value: CAPTURE_TYPE_AUTO,
    label: 'Auto'
  },
  {
    value: CAPTURE_TYPE_MULTI_KINECT_DEPTH_KIT,
    label: 'Azure Kinect - Depthkit'
  },
  { value: CAPTURE_TYPE_IPHONE_VIMMERSE, label: 'iPhone - Vimmerse' },
  { value: CAPTURE_TYPE_IPHONE_RECORD3D, label: 'iPhone - Record3D' },
  { value: CAPTURE_TYPE_MULTI_CAMERA_MIV, label: 'Multi Camera - MIV' },
  { value: CAPTURE_TYPE_MULTI_KINECT_OPEN3D, label: 'Azure Kinect - Open3D' },
  { value: CAPTURE_TYPE_3DVIDEO_VIMMERSE, label: '3DVideo - Vimmerse' }
];

export const PROCESSING_STATUS_NEW = 'new';

export const PROCESSING_STATUS_QUEUE_SOURCE = 'queue_source';
export const PROCESSING_STATUS_PROCESSING_SOURCE = 'processing_source';
export const PROCESSING_STATUS_SUCCESS_SOURCE = 'success_source';
export const PROCESSING_STATUS_FAILED_SOURCE = 'failed_source';

export const PROCESSING_STATUS_QUEUE_3D = 'queue_3d';
export const PROCESSING_STATUS_PROCESSING_3D = 'processing_3d';
export const PROCESSING_STATUS_SUCCESS_3D = 'success_3d';
export const PROCESSING_STATUS_FAILED_3D = 'failed_3d';

export const PROCESSING_STATUS_QUEUE_BULLET = 'queue_bullet';
export const PROCESSING_STATUS_PROCESSING_BULLET = 'processing_bullet';
export const PROCESSING_STATUS_FAILED_BULLET = 'failed_bullet';

export const PROCESSING_STATUS_PROCESSING = 'processing';
export const PROCESSING_STATUS_SUCCESS = 'success';
export const PROCESSING_STATUS_FAIL = 'fail';

export const PROCESSING_STATUS_ACTION_REQUIRED = 'action required';
export const PROCESSING_STATUS_CANCEL = 'cancel';

export const STATUS_STRING_MAP = {
  [PROCESSING_STATUS_NEW]: 'New',
  [PROCESSING_STATUS_QUEUE_SOURCE]: 'Waiting for source generation',
  [PROCESSING_STATUS_PROCESSING_SOURCE]: 'Generating source',
  [PROCESSING_STATUS_SUCCESS_SOURCE]: 'Generated source',
  [PROCESSING_STATUS_FAILED_SOURCE]: 'Failed source generation',
  [PROCESSING_STATUS_QUEUE_3D]: 'Waiting for 3D generation',
  [PROCESSING_STATUS_PROCESSING_3D]: 'Generating 3D media',
  [PROCESSING_STATUS_SUCCESS_3D]: 'Generated 3D media',
  [PROCESSING_STATUS_FAILED_3D]: 'Failed 3D generation',
  [PROCESSING_STATUS_QUEUE_BULLET]: 'Waiting for bullet video generation',
  [PROCESSING_STATUS_PROCESSING_BULLET]: 'Processing bullet video',
  [PROCESSING_STATUS_SUCCESS]: 'Success',
  [PROCESSING_STATUS_FAILED_BULLET]: 'Failed bullet video generation',
  [PROCESSING_STATUS_ACTION_REQUIRED]: 'Action required',
  [PROCESSING_STATUS_CANCEL]: 'Cancelled'
};

export const PUBLIC_STATUS_PUBLIC = '2';
export const PUBLIC_STATUS_UNLISTED = '1';
export const PUBLIC_STATUS_PRIVATE = '0';

export const PUBLIC_STATUS_OPTIONS = [
  { value: PUBLIC_STATUS_UNLISTED, label: 'Unlisted' },
  { value: PUBLIC_STATUS_PUBLIC, label: 'Public' },
  { value: PUBLIC_STATUS_PRIVATE, label: 'Private' }
];

export const ENV_OPTIONS = [
  { value: 'prod', label: 'Production' },
  { value: 'dev', label: 'Development' }
];
export const AI_TYPES = [
  'Text2Video',
  'Auto',
  'Prompt',
  'Control',
  'StableVideoDiffusion',
  'LeonardoMotion',
  'HaiperVideo',
  'TryOnVideo',
  'KlingAI',
  'MinimaxHailuo',
  'DynamiCrafter',
  'LumaAI',
  'RunwayML',
  'DynamiCrafterInterp'
];

export const AI_SERVICES = [
  'StableVideoDiffusion',
  'LeonardoMotion',
  'HaiperVideo',
  'KlingAI',
  'MinimaxHailuo',
  'DynamiCrafter',
  'LumaAI',
  'RunwayML',
]

export const ANIMATEAI_QUANTITY = 1;

export const DEFAULT_POSE_PRESET_PARAMS =
  'MotionAmount=5|MotionLength=5.0|Loop=1';
export const DEFAULT_POSE_PRESET = {
  MotionType: 'Parallax',
  Params: 'CameraPath=StepIn|' + DEFAULT_POSE_PRESET_PARAMS
};

export const MOTION_TYPE_OPTIONS = [
  { value: 'Auto', label: 'Auto' },
  { value: 'Prompt', label: 'Prompt' },
  { value: 'Text2Video', label: 'Text2Video' },
  { value: 'Control', label: 'Control' },
  { value: 'LeonardoMotion', label: 'LeonardoMotion' },
  { value: 'HaiperVideo', label: 'HaiperVideo' },
  { value: 'TryOnVideo', label: 'TryOnVideo' },
  { value: 'KlingAI', label: 'KlingAI' },
  { value: 'MinimaxHailuo', label: 'MinimaxHailuo' },
  { value: 'StableVideoDiffusion', label: 'StableVideoDiffusion' },
  { value: 'DynamiCrafter', label: 'DynamiCrafter' },
  { value: 'DynamiCrafterInterp', label: 'DynamiCrafterInterp' },
  { value: 'HorizontalArc', label: 'Horizontal' },
  { value: 'VerticalArc', label: 'Vertical' },
  { value: 'Cross', label: 'Cross' },
  { value: 'Circular', label: 'Circular' },
  { value: 'StepIn', label: 'StepIn' },
  { value: 'StepL', label: 'StepL' },
  { value: 'StepR', label: 'StepR' },
  { value: 'StepU', label: 'StepU' },
  { value: 'StepD', label: 'StepD' }
];

export const DEFAULT_GENERATE_AI = {
  tool: 'Text2Image', //# options: 'Text2Image', 'Reimagine', 'Inpaint', 'Cleanup', 'ReplaceBackground', 'RemoveBackground', 'Recrop', 'RemoveText', 'Sketch2Image', 'Instruct-pix2pix', 'ControlNet', 'Enhance'
  quantity: '1',
  input: 'prompt=Shot of Nike shoes on colorful background',
  params: 'option=|',
  output: 'ai_image.png',
  type: 'source'
};

export const SUPPORTED_AUDIO_FORMATS = ['aac', 'mp3', 'ogg', 'wav', 'm4a'];
export const SUPPORTED_IMAGE_FORMATS = [
  'png',
  'jpg',
  'jpeg',
  'svg',
  'heif',
  'heic',
  'webp',
  'tif',
  'tiff',
  'bmp',
  'psd',
  'avif',
  'jfif'
];
export const SUPPORTED_VIDEO_FORMATS = ['mp4', 'mov', 'gif', 'mkv', 'ogv'];

export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/quicktime',
  'video/x-matroska',
  'video/ogg',
  'video/webm',
  'video/mpeg',
  'video/x-m4v',
  'video/x-flv',
  'video/x-msvideo',
  'video/x-ms-wmv'
];

export const IMAGE_MIME_TYPES = [
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'image/heif',
  'image/heic',
  'image/webp',
  'image/tiff',
  'image/bmp',
  'image/vnd.adobe.photoshop',
  'image/avif'
];

export const FILE_TYPE_OPTIONS = [
  { value: 'Texture', label: 'Texture' },
  { value: 'Depth', label: 'Depth' },
  { value: 'Mask', label: 'Mask' },
  { value: 'BG Texture', label: 'BG Texture' },
  { value: 'BG Depth', label: 'BG Depth' },
  { value: 'Metadata', label: 'Metadata' },
  { value: 'Audio', label: 'Audio' },
  { value: 'PoseTrace', label: 'PoseTrace' },
  { value: 'Config', label: 'Config' },
  { value: 'Unknown', label: 'Unknown' },
  { value: 'Skip', label: 'Skip' }
];

export const VIMMERSE_CUSTOMER_ID = 'vimmerse-product';
export const VIMMERSE_CREATOR_ID = 'vimmerse-creator';

export const PIPELINE_PRESET_NORMAL = 'Normal';
export const PIPELINE_PRESET_FAST = 'Fast';

export const DEFAULT_SUBMIT_PARAMS = {
  creation_version: '1',
  scale_factor: '1',
  rotation: '0',
  mask_source: 'Auto',
  mask_processing: 'Auto',
  text_processing: 'Yes',
  depth_processing: 'Auto',
  erosion_radius: '5',
  background_processing: 'Auto',
  stabilize_motion: 'No',
  virtual_views: '-1',
  virtual_motion_amount: '4',
  renderer_type: 'Auto',
  random_seed: 'Yes',
  nsfw_filter_type: 'Auto',
  pipeline_preset: PIPELINE_PRESET_NORMAL
};

export const GENERATE_ALL = 'GENERATE_ALL';
export const GENERATE_AI = 'GENERATE_AI';
export const GENERATE_SOURCE = 'GENERATE_SOURCE';
export const GENERATE_3D = 'GENERATE_3D';
export const GENERATE_BULLET = 'GENERATE_BULLET';
export const GENERATE_VIDEO = 'GENERATE_VIDEO';

export const DEFAULT_GENERATE_TYPE = GENERATE_ALL;

export const PROCESSING_STATUS_OPTIONS = [
  {
    value: PROCESSING_STATUS_NEW,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_NEW]
  },
  {
    value: PROCESSING_STATUS_QUEUE_SOURCE,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_QUEUE_SOURCE]
  },
  {
    value: PROCESSING_STATUS_PROCESSING_SOURCE,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_PROCESSING_SOURCE]
  },
  {
    value: PROCESSING_STATUS_SUCCESS_SOURCE,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_SUCCESS_SOURCE]
  },
  {
    value: PROCESSING_STATUS_FAILED_SOURCE,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_FAILED_SOURCE]
  },
  {
    value: PROCESSING_STATUS_QUEUE_3D,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_QUEUE_3D]
  },
  {
    value: PROCESSING_STATUS_PROCESSING_3D,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_PROCESSING_3D]
  },
  {
    value: PROCESSING_STATUS_SUCCESS_3D,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_SUCCESS_3D]
  },
  {
    value: PROCESSING_STATUS_FAILED_3D,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_FAILED_3D]
  },
  {
    value: PROCESSING_STATUS_QUEUE_BULLET,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_QUEUE_BULLET]
  },
  {
    value: PROCESSING_STATUS_PROCESSING_BULLET,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_PROCESSING_BULLET]
  },
  {
    value: PROCESSING_STATUS_SUCCESS,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_SUCCESS]
  },
  {
    value: PROCESSING_STATUS_FAILED_BULLET,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_FAILED_BULLET]
  },
  {
    value: PROCESSING_STATUS_ACTION_REQUIRED,
    label: STATUS_STRING_MAP[PROCESSING_STATUS_ACTION_REQUIRED]
  }
];

export const UPLOAD_TO_UPLOAD = 'UPLOAD';
export const UPLOAD_TO_SOURCE = 'SOURCE';

export const QUEUE_TYPE_BATCH = 'BATCH';
export const QUEUE_TYPE_NORMAL = 'NORMAL';

export const BATCH_STATUS_NEW = 'new';
export const BATCH_STATUS_PROCESSING = 'processing';
export const BATCH_STATUS_PROCESSED = 'processed';
export const BATCH_STATUS_SUCCESS = 'success';
export const BATCH_STATUS_FAIL = 'fail';
export const BATCH_STATUS_READY = 'ready';
export const BATCH_STATUS_PAID = 'paid';

export const BATCH_STATUS_OPTIONS = [
  {
    value: BATCH_STATUS_NEW,
    label: BATCH_STATUS_NEW.toUpperCase()
  },
  {
    value: BATCH_STATUS_PROCESSING,
    label: BATCH_STATUS_PROCESSING.toUpperCase()
  },
  {
    value: BATCH_STATUS_PROCESSED,
    label: BATCH_STATUS_PROCESSED.toUpperCase()
  },
  {
    value: BATCH_STATUS_SUCCESS,
    label: BATCH_STATUS_SUCCESS.toUpperCase()
  },
  {
    value: BATCH_STATUS_FAIL,
    label: BATCH_STATUS_FAIL.toUpperCase()
  },
  {
    value: BATCH_STATUS_READY,
    label: BATCH_STATUS_READY.toUpperCase()
  },
  {
    value: BATCH_STATUS_PAID,
    label: BATCH_STATUS_PAID.toUpperCase()
  }
];

export const DISCOUNT_OPTIONS = [
  { value: '0', label: '0%' },
  { value: '5', label: '5%' },
  { value: '10', label: '10%' },
  { value: '20', label: '20%' },
  { value: '30', label: '30%' },
  { value: '50', label: '50%' },
  { value: '70', label: '70%' },
  { value: '90', label: '90%' },
  { value: '100', label: '100%' }
];

export const IMPORTANT_CUSTOMERS = [
  'vimmerse-product',
  'canva',
  'canvaremake',
  'canvatryon',
  'adobe',
  'shopify',
];

export const RATING_OPTIONS = [
  { value: '-1', label: 'No Ratings' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' }
];

export const VCV_SERVER_MAP = [
  {
    name: 'VCV TOOL 1',
    ip: '164.52.147.242'
  },
  {
    name: 'VCV TOOL 2',
    ip: '164.52.147.243'
  },
  {
    name: 'VCV TOOL 3',
    ip: '164.52.147.244'
  },
  {
    name: 'VCV TOOL 4',
    ip: '164.52.147.245'
  },
  {
    name: 'VCV TOOL 5',
    ip: '164.52.147.251'
  },
  {
    name: 'VCV TOOL 6',
    ip: '164.52.147.252'
  },
  {
    name: 'VCV TOOL 7',
    ip: '164.52.147.253'
  },
  {
    name: 'VCV TOOL 8',
    ip: '164.52.147.254'
  }
];

export const JENKINS_ADDRESSES = [
  {
    ip: '164.52.147.242',
    jenkins: 'https://vcv-1-a100-jenkins.vimmerse.io'
  },
  {
    ip: '164.52.147.243',
    jenkins: 'https://vcv-2-a100-jenkins.vimmerse.io'
  },
  {
    ip: '164.52.147.244',
    jenkins: 'https://vcv-3-a100-jenkins.vimmerse.io'
  },
  {
    ip: '164.52.147.245',
    jenkins: 'https://vcv-4-a100-jenkins.vimmerse.io'
  },
  {
    ip: '164.52.147.251',
    jenkins: 'https://vcv-5-a100-jenkins.vimmerse.io'
  },
  {
    ip: '164.52.147.252',
    jenkins: 'https://vcv-6-a100-jenkins.vimmerse.io'
  },
  {
    ip: '164.52.147.253',
    jenkins: 'https://vcv-7-a100-jenkins.vimmerse.io'
  },
  {
    ip: '164.52.147.254',
    jenkins: 'https://vcv-8-a100-jenkins.vimmerse.io'
  },
  {
    ip: '129.146.6.109',
    jenkins: 'https://lambdalabs-jenkins-a100-4.vimmerse.io'
  },
  {
    ip: '129.146.39.242',
    jenkins: 'https://lambdalabs-jenkins-a100-9.vimmerse.io'
  },
  {
    ip: '129.213.147.106',
    jenkins: 'https://lambdalabs-jenkins-a100-test.vimmerse.io'
  },
  {
    ip: '129.80.105.160',
    jenkins: 'https://lambdalabs-jenkins-a100-17.vimmerse.io'
  },
  {
    ip: '129.146.4.30',
    jenkins: 'https://lambdalabs-jenkins-a100-2.vimmerse.io'
  },
  {
    ip: '129.213.93.244',
    jenkins: 'https://lambdalabs-jenkins-a100-3.vimmerse.io/'
  }
];

export const PRICE_ID_STANDARD = 'price_1PIhvAICs2gSRULGfThpBD9S'
export const PRICE_ID_PRO = 'price_1PIhv7ICs2gSRULGmtOLv2x2'
export const PRICE_ID_BUSINESS = 'price_1PIhv4ICs2gSRULGlWo23pnX'
export const PRICE_ID_ENTERPRISE = 'price_1PIhv0ICs2gSRULGNtYwH2oA'
export const CREDITS_STANDARD = 1000
export const CREDITS_PRO = 10000
export const CREDITS_BUSINESS = 100000
export const CREDITS_ENTERPRISE = 1000000

export const PRICE_PLANS = {
  'standard': {
      'price_id': PRICE_ID_STANDARD,
      'credits': CREDITS_STANDARD,
      'description': '1K credits for $14'
  },
  'pro': {
      'price_id': PRICE_ID_PRO,
      'credits': CREDITS_PRO,
      'description': '10K credits for $120'
  },
  'business': {
      'price_id': PRICE_ID_BUSINESS,
      'credits': CREDITS_BUSINESS,
      'description': '100K credits for $1000'
  },
  'enterprise': {
      'price_id': PRICE_ID_ENTERPRISE,
      'credits': CREDITS_ENTERPRISE,
      'description': '1M credits for $9000'
  }
}

export const AI_TOOL_STATUS_NEW = 'NEW';
export const AI_TOOL_STATUS_REQUESTED = 'REQUESTED';
export const AI_TOOL_STATUS_FINISHED = 'FINISHED';
export const AI_TOOL_STATUS_FAILED = 'FAILED';