/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import './AssetDetail.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getAsset } from 'redux-layer/actions';
import { DetailViewItem } from 'components/DetailItemView';
import { UserName } from 'components/UserName';
import { convertUTCtoLocal } from 'helper/utils';

const AssetDetail = props => {
  const { asset_id } = useParams();

  const dispatch = useDispatch();

  const { paginatedAssets, assetDetail } = useSelector(state => ({
    paginatedAssets: state.assets.paginatedAssets,
    assetDetail: state.assets.assetDetail
  }));

  const currentAsset = useMemo(() => {
    if (assetDetail) {
      if (assetDetail.id === asset_id) {
        return assetDetail;
      }
    } else {
      if (paginatedAssets) {
        return paginatedAssets.find(asset => asset.id === asset_id);
      }
    }
    return null;
  }, [paginatedAssets, assetDetail]);

  useEffect(() => {
    if (!currentAsset) {
      dispatch(getAsset(asset_id));
    }
  }, [currentAsset]);

  if (!currentAsset) {
    return (
      <div className='asset-detail-page'>
        <i className='fas fa-redo fa-spin' />
      </div>
    );
  }

  console.log(currentAsset);

  return (
    <div className='asset-detail-page'>
      <div className='page-header'>
        <h1>Asset - {currentAsset.app_name}</h1>
      </div>
      <div className='contents'>
        <div className='row'>
          <div className='col-md-6'>
            <h3>Input</h3>
            <div className='row'>
              <div className='col'>
                <DetailViewItem
                  title='User ID'
                  value={
                    <UserName primary_user_id={currentAsset.primary_user_id} />
                  }
                />
              </div>
              <div className='col'>
                <DetailViewItem
                  title='Customer'
                  value={currentAsset.customer_id}
                />
              </div>
              <div className='col'>
                <DetailViewItem
                  title='From API?'
                  value={currentAsset.is_internal ? 'Yes' : 'No'}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <h5>Args</h5>
                <textarea
                  readOnly
                  value={JSON.stringify(currentAsset.args, undefined, 2)}
                  style={{ width: '100%' }}
                  rows={30}
                />
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <h3>Results</h3>

            {currentAsset.image_history.map(historyItem => (
              <>
                <div className='row mt-5'>
                  <div className='col-12'>
                    <DetailViewItem
                      title='Created At'
                      value={convertUTCtoLocal(historyItem.created_at).format(
                        'yyyy-MM-DD HH:mm'
                      )}
                    />
                  </div>
                  <div className='col-12'>
                  <h5>Args</h5>
                  <textarea
                    readOnly
                    value={JSON.stringify(historyItem.args, undefined, 2)}
                    style={{ width: '100%' }}
                    rows={5}
                  />
                </div>
                </div>
                <div className='row'>
                  {historyItem.results.map(img_result => (
                    <div className='col' key={img_result}>
                      <img
                        src={img_result}
                        className='asset-result'
                        alt={img_result}
                      />
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { AssetDetail };
