import { combineReducers } from 'redux';
import usersReducer from './usersReducer';
import mediaReducer from './mediaReducer';
import customersReducer from './customersReducer';
import batchReducer from './batchReducer.js';
import toolsReducer from './toolsReducer';
import queueReducer from './queueReducer';
import ec2Reducer from './ec2Reducer';
import statisticsReducer from './statisticsReducer';
import settingReducer from './settingReducer';
import serviceReducer from './serviceReducer';
import assetsReducer from './assetsReducer';
import couponsReducer from './couponsReducer';

export default combineReducers({
  users: usersReducer,
  media: mediaReducer,
  customers: customersReducer,
  batch: batchReducer,
  tools: toolsReducer,
  queue: queueReducer,
  ec2: ec2Reducer,
  statistic: statisticsReducer,
  settings: settingReducer,
  services: serviceReducer,
  assets: assetsReducer,
  coupons: couponsReducer,
});
