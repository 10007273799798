import { CustomLegend, CustomTooltip } from 'components/GraphComponents';
import './AIStatistics.scss';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const labels = {
  'count.NEW': 'New',
  'count.REQUESTED': 'Requested',
  'count.FINISHED': 'Finished'
};

const AIStatistics = props => {
  const { data } = props;

  const aiUsages = Object.keys(data).map(motion_key => {
    const ai_usage = { ...data[motion_key] };
    ai_usage['motion_type'] = motion_key;
    return ai_usage;
  });

  return (
    <div>
      <h5>AI Tool Usage</h5>
      <div className='chart-wrapper'>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart width={800} height={300} data={aiUsages}>
            <Bar stackId='a' dataKey='count.NEW' fill='#1205fa' />
            <Bar stackId='a' dataKey='count.REQUESTED' fill='#f71acc' />
            <Bar stackId='a' dataKey='count.FINISHED' fill='#1ddb12' />
            <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
            <XAxis dataKey='motion_type' />
            <YAxis />
            <Tooltip content={<CustomTooltip labels={labels} />} />
            <Legend content={<CustomLegend labels={labels} />} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export { AIStatistics };
