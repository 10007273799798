import { useState } from 'react';
import {
  Button,
  Form,
  FormControl,
  Modal,
} from 'react-bootstrap';
import { QRCode } from 'react-qrcode-logo';
import { useRef } from 'react';

const QRCodeDialog = ({ onClose, couponUrl }) => {
  const [url, setUrl] = useState(couponUrl);
  const qrCodeRef = useRef(null);
  const handleDownload = () => {
    qrCodeRef.current.download('png', 'vimmerse_qr_code');
  }
  return (
    <Modal show={true} onHide={onClose} backdrop='static' size='md'>
      <Modal.Header>QR Code</Modal.Header>
      <Modal.Body>
        <Form>
          <FormControl
            type='text'
            value={url}
            required
            placeholder='Inut URL'
            onChange={e => setUrl(e.target.value)}
          />
        </Form>
        <div className='text-center'>
          <QRCode
            ref={qrCodeRef}
            value={url}
            size={400}
            logoImage={'/Vimmerse-logo.png'}
            logoWidth={400 * 0.25}
            logoHeight={400 * 0.25}
            logoPadding={1}
            logoOpacity={0.8}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleDownload}
          variant='primary'
          disabled={url === ''}
        >
          Download
        </Button>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { QRCodeDialog };
