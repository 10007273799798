import { actionConstants } from '../constants';
import {
  successReqActionType,
  failReqActionType,
  startReqActionType
} from 'helper/requestHelper';

const initialState = {
  error: null,
  
  paginatedCoupons: [],
  lastEvaluatedKeys: null,
  totalCount: 0,
  couponDetail: null,

  isLoadingCouponsList: false,

  isLoadingCoupon: false
};

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.PAGINATED_LIST_COUPONS):
      return {
        ...state,
        isLoadingCouponsList: true
      };

    case successReqActionType(actionConstants.PAGINATED_LIST_COUPONS):
      return {
        ...state,
        isLoadingCouponsList: false,
        paginatedCoupons: action.payload.reset
          ? action.payload.data
          : [...state.paginatedCoupons, ...action.payload.data],
        lastEvaluatedKeys: action.payload.last_evaluated_key,
        totalCount: action.payload.reset
          ? action.payload.total_count
          : state.totalCount
      };
    case failReqActionType(actionConstants.PAGINATED_LIST_COUPONS):
      return {
        ...state,
        isLoadingCouponsList: false
      };
    
    case startReqActionType(actionConstants.GET_COUPON_DETAIL):
      return {
        ...state,
        isLoadingCoupon: true
      };

    case successReqActionType(actionConstants.GET_COUPON_DETAIL):
      return {
        ...state,
        couponDetail: action.payload,
        isLoadingCoupon: false
      };
    case failReqActionType(actionConstants.GET_COUPON_DETAIL):
      return {
        ...state,
        isLoadingCoupon: false
      };
    default:
      return state;
  }
};

export default couponReducer;
